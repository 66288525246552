export const SVGs = {
  instincthub_logo: require("../svg/logo.svg").default.src,
  email: require("../svg/mail_FILL0_wght400_GRAD0_opsz48.svg").default.src,
  location: require("../svg/location.svg").default.src,
  phone: require("../svg/phone.svg").default.src,
  shareSvg: require("../svg/shareSvg.svg").default.src,
  likeSvg: require("../svg/likeSvg.svg").default.src,
  facebook: require("../svg/facebook.svg").default.src,
  NotFound: require("../svg/NotFound.svg").default.src,
  Error500: require("../svg/Error500.svg").default.src,
  youtube: require("../svg/youtube.svg").default.src,
  linkedin: require("../svg/linkedin.svg").default.src,
  multipleUsers: require("../svg/multipleUser.svg").default.src,
  OneUser: require("../svg/one_user.svg").default.src,
  linkedin_b: require("../svg/linkedin_b.svg").default.src,
  twitter: require("../svg/twitter.svg").default.src,
  close: require("../svg/close.svg").default.src,
  sent: require("../svg/sent.svg").default.src,
  opened: require("../svg/opened.svg").default.src,
  click: require("../svg/clicks.svg").default.src,
  bounced: require("../svg/bounced.svg").default.src,
  downloaded: require("../svg/downloadable.svg").default.src,
  download: require("../svg/download_FILL0_wght300_GRAD0_opsz24.svg").default.src,
  unsubscribed: require("../svg/unscribed.svg").default.src,
  cloud: require("../svg/cloud.svg").default.src,
  files: require("../svg/files.svg").default.src,
  zip: require("../svg/zip.svg").default.src,
  pdf: require("../svg/pdf.svg").default.src,
  instagram: require("../svg/instagram.svg").default.src,
  w_facebook: require("../svg/w_facebook.svg").default.src,
  w_twitter: require("../svg/w_twitter.svg").default.src,
  w_linkedin: require("../svg/w_linkedin.svg").default.src,
  w_whatsapp: require("../svg/w_whatsapp.svg").default.src,
  w_ig: require("../svg/w_ig.svg").default.src,
  accessable: require("../svg/accessable.svg").default.src,
  affordable: require("../svg/affordable.svg").default.src,
  community: require("../svg/community.svg").default.src,
  support: require("../svg/support.svg").default.src,
  group: require("../svg/group.svg").default.src,
  chevron_down: require("../svg/chevron_down.svg").default.src,
  clock: require("../svg/clock.svg").default.src,
  star_gif: require("../svg/stargif.svg").default.src,
  mail: require("../svg/mail.svg").default.src,
  dollar: require("../svg/dollar.svg").default.src,
  growth: require("../svg/growth_chart.svg").default.src,
  plane: require("../svg/plane.svg").default.src,
  workstation: require("../svg/workstation.svg").default.src,
  team: require("../svg/team.svg").default.src,
  visibility: require("../svg/visibility_FILL0_wght400_GRAD0_opsz48.svg").default.src,
  visibility_off: require("../svg/visibility_off_FILL0_wght400_GRAD0_opsz48.svg").default.src,
};
